
//import React, { useState, useEffect } from 'react';
import React from 'react';

import { createBrowserHistory } from 'history';
import ForceGraph3D from 'react-force-graph-3d';
import SpriteText from 'three-spritetext';

//import logo from './logo.svg';

import manifest from './eks-manifest.json';
import './App.css';
import dataset from './dataset-comp.json';

const distance = 420;
//const step = Math.PI / 600.0;
const stepAzi = Math.PI / 600.0;
const dur = 16000;
var dir = 1;

var easeInOutCubic = function (t, b, c, d) {
	t /= d/2;
	if (t < 1) return c/2*t*t*t + b;
	t -= 2;
	return c/2*(t*t*t + 2) + b;
};
/*
var easeInOutExpo = function (t, b, c, d) {
	t /= d/2;
	if (t < 1) return c/2 * Math.pow( 2, 10 * (t - 1) ) + b;
	t--;
	return c/2 * ( -Math.pow( 2, -10 * t) + 2 ) + b;
};
var linear = function (t, b, c, d) {
	return c*t/d + b;
};
*/

class EksFG extends React.Component {
	componentDidMount() {
		/*
		var mb = this.fg.d3Force('charge');
		mb.strength(-700);
		var controls = this.fg.controls;
		controls.autoRotate = true;
		controls.autoRotateSpeed = 30.0;
		var camera = this.fg.camera;

		let angle = 0;
		setInterval(() => {
			this.fg.cameraPosition({
				x: distance * Math.sin(angle),
				z: distance * Math.cos(angle)
			});
			angle += Math.PI / 300;
		}, 30);
		*/

		this.fg.cameraPosition({ z: distance });
		let azi = 0, pol = Math.PI / 2, t = dur/2;
		setInterval(() => {
			this.fg.cameraPosition({
				x: distance * Math.sin(azi) * Math.sin(pol),
				z: distance * Math.cos(azi) * Math.sin(pol),
				y: distance * Math.cos(pol)
			});
			azi += stepAzi;
			//pol = easeInOutExpo(t, 0, Math.PI, dur);
			//pol = linear(t, Math.PI / 5, 3 * Math.PI / 5, dur);
			pol = easeInOutCubic(t, Math.PI / 5, 3 * Math.PI / 5, dur);
			t += dir;
			if (t > dur) {
				dir = -1;
				t += 2 * dir;
			}
			else if (t < 0) {
				dir = +1;
				t += 2 * dir;
			}
		}, 30);

		/*this.fg.zoom(12);*/

		/*this.fg.cameraPosition({x: 0, y: 0, z: 39}, {x: 0, y: 0, z: 0}, 12000)*/
	}

	render() {
		return <div style={{position: "relative", top: "-44px"}}>
			<ForceGraph3D
			ref={el => this.fg = el}
			graphData={dataset}
			nodeAutoColorBy="group"
			nodeId="index"
			cooldownTicks={1}
			linkVisibility={false}

			enableNodeDrag={false}
			enableNavigationControls={false}
			showNavInfo={false}

			onNodeClick={(e) => {
				var cp = this.fg.camera();
				console.dir(cp);
			}}
			nodeThreeObject={node => {
				const sprite = new SpriteText(node.id);
				sprite.color = node.color;
				sprite.textHeight = node.sz || 8;
				return sprite;
			}}
		/></div>;
	}
}
/*
		<footer className="foots">
			<div className="foot mw gf">
				<div className="fo1">{manifest.copy1}<br/>{manifest.copy2}</div>
					<div className="fo2"></div>
					<div className="fo3"></div>
			</div>
		</footer>
*/
function App() {

	return (<>
		<EksFG />
	</>);
}

export default App;

